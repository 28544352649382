import React from 'react';
import Logo from '~images/Opinaia.svg';
import './styles.scss';

const Header = () => (
  <header className="header">
    <div className="header__inner-container">
      <Logo className="header__logo" />
    </div>
  </header>
);

export default Header;
