import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '~components/Header';
import Footer from '~components/Footer';
import './styles.scss';

const AlreadyRegistered = () => {
  const [secondsLeft, setSecondsLeft] = useState(6);

  const { t } = useTranslation('common');

  let timer;
  useEffect(() => {
    if (secondsLeft) {
      timer = setInterval(() => {
        setSecondsLeft(prev => prev - 1);
      }, 1000);
    } else {
      window.location.replace('https://www.opinaia.com/auth/login');
    }

    return () => clearInterval(timer);
  }, [secondsLeft]);

  return (
    <div className="registered">
      <Header />
      <div className="registered__container">
        <p className="registered__text">{t('alreadyRegistered.text', { seconds: secondsLeft })}</p>
      </div>
      <Footer />
    </div>
  );
};

export default AlreadyRegistered;
