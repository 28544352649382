import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';

function DangerouslySetHtmlContent({ html, ...rest }) {
  const divRef = useRef(null);

  useEffect(() => {
    if (!html) return;

    const slotHtml = document.createRange().createContextualFragment(html);
    divRef.current.innerHTML = '';
    divRef.current.appendChild(slotHtml);
  }, [html]);

  return <div {...rest} ref={divRef} />;
}

DangerouslySetHtmlContent.propTypes = {
  html: propTypes.string.isRequired,
};

export default DangerouslySetHtmlContent;
