import 'babel-polyfill';
import 'core-js/proposals/string-replace-all';
import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import App from '~App';
import './index.scss';
import './i18n';

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
