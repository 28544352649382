import React from 'react';
import propTypes from 'prop-types';
import './styles.scss';

const ErrorPage = ({ errorCode }) => {
  if (!errorCode) return null;
  return (
    <div className="error-page">
      <h1 className="error-page--code">{errorCode}</h1>
      <p className="error-page--text">{errorCode === 404 ? 'NOT FOUND' : 'ERROR'}</p>
    </div>
  );
};

ErrorPage.propTypes = {
  errorCode: propTypes.number,
};

ErrorPage.defaultProps = {
  errorCode: null,
};

export default ErrorPage;
