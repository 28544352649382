import React from 'react';
import ErrorPage from '../ErrorPage';
import Loader from '~components/Loader';
import useGetTemplate from '~hooks/useGetTemplate';
import DangerouslySetHtmlContent from '~components/DangerouslySetHtmlContent';
import { useCampaign } from '~Flow';

const imagesBaseUrl = 'https://opinaia-assets.s3.sa-east-1.amazonaws.com/flow-popular/';

const emailType = {
  gmail: { url: 'https://www.google.com/gmail', image: 'gmail.png' },
  hotmail: { url: 'https://outlook.live.com/', image: 'hotmail.png' },
  icloud: { url: 'https://www.icloud.com/mail', image: 'iCloud.png' },
  yahoo: { url: 'https://mail.yahoo.com/', image: 'yahoo.png' },
};

const EndingNoRedirectPage = () => {
  const { endingNoRedirectHtml, error, loading } = useGetTemplate();

  const {
    userData: { email },
  } = useCampaign();

  if (error) return <ErrorPage errorCode={error} />;

  if (loading) return <Loader />;

  const [domain] = email?.split('@')[1].split('.');

  return (
    <DangerouslySetHtmlContent
      html={endingNoRedirectHtml
        .replaceAll(
          '{{mailLink}}',
          emailType[domain] ? emailType[domain].url : 'https://www.google.com/',
        )
        .replaceAll('{{mailImage}}', `${imagesBaseUrl}${emailType[domain]?.image}`)}
    />
  );
};

export default EndingNoRedirectPage;
