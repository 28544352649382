import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCampaign } from '~Flow';
import Header from '~components/Header';
import Footer from '~components/Footer';
import WomanImg from '~images/animation_moni.png';
import WomanImgDesktop from '~images/moni-completa.png';
import AnimationWheel from '~images/animation_wheel.png';
import AnimationCheck from '~images/animation_check.png';
import LoadingIcon from '~images/icon_loading.png';
import CheckIcon from '~images/icon_check.png';
import './styles.scss';

const AwaitOpinaia = () => {
  const [stepChecked, setStepChecked] = useState(0);
  const [progress, setProgress] = useState(0);

  const { url } = useCampaign();
  const history = useHistory();
  const { t } = useTranslation('common');

  useEffect(() => {
    let timer;
    if (stepChecked <= 3) {
      timer = setTimeout(() => setStepChecked(prev => prev + 1), 1500);
    }
    if (stepChecked === 3) {
      timer = setTimeout(() => {
        history.push(`${url}/ending`);
      }, 1500);
    }

    return () => clearTimeout(timer);
  }, [stepChecked]);

  useEffect(() => {
    let timer;
    if (progress < 100) {
      timer = setTimeout(() => setProgress(prev => prev + 1), 40);
    }
    return () => clearTimeout(timer);
  }, [progress]);

  return (
    <div className="await">
      <Header />
      <div className="await__container">
        <div className="await__inner-container">
          <div className="await__main-image--background">
            <div className="await__main-image--container">
              <img className="await__main-image" src={WomanImg} alt="" height="252" />
              <img
                className={`await__main-image--icon${progress < 98 ? ' loading' : ''}`}
                src={progress < 98 ? AnimationWheel : AnimationCheck}
                alt=""
              />
            </div>
          </div>
          <div className="await__main-image-desktop--container">
            <img className="await__main-image-desktop" src={WomanImgDesktop} alt="" height="389" />
            <img
              className={`await__main-image--icon${progress < 98 ? ' loading' : ''}`}
              src={progress < 98 ? AnimationWheel : AnimationCheck}
              alt=""
            />
          </div>
          <div className="await__steps-container">
            <div className="await__progress-bar--texts">
              <p>{stepChecked < 3 ? t('await.verifying') : t('await.excelent')}</p>
              <span>{progress}%</span>
            </div>
            <div className="await__progress-bar">
              <div className="await__progress-bar--progress" style={{ width: `${progress}%` }} />
            </div>
            <div className="await__step">
              <img
                className={`await__step--img${stepChecked < 1 ? ' loading' : ''}`}
                src={stepChecked < 1 ? LoadingIcon : CheckIcon}
                alt=""
              />
              <p className="await__step--text">{t('await.step1')}</p>
            </div>
            <div className="await__step">
              <img
                className={`await__step--img${stepChecked < 2 ? ' loading' : ''}`}
                src={stepChecked < 2 ? LoadingIcon : CheckIcon}
                alt=""
              />
              <p className="await__step--text">{t('await.step2')}</p>
            </div>
            <div className="await__step">
              <img
                className={`await__step--img${stepChecked < 3 ? ' loading' : ''}`}
                src={stepChecked < 3 ? LoadingIcon : CheckIcon}
                alt=""
              />
              <p className="await__step--text">{t('await.step3')}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AwaitOpinaia;
