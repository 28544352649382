const handleSurveys = (surveysResponse, campaignSurvey) => {
  if (!surveysResponse || !surveysResponse.length) return null;

  const surveysFields = surveysResponse.filter(({ Campaigns }) => !!Campaigns);

  const registerSurvey = surveysFields.find(field => field.Type === 'Register');

  const register = registerSurvey?.Definition?.pages.reduce(
    (acc, { elements }) => [
      ...acc,
      ...elements.reduce((subAcc, { name, type, elements: subElements }) => {
        if (type === 'panel') {
          return [...subAcc, ...subElements.map(({ name: subElementName }) => subElementName)];
        }
        return [...subAcc, name];
      }, []),
    ],
    [],
  );

  const orderedSurveysPages = surveysFields
    .sort((a, b) => campaignSurvey.indexOf(a.Id) - campaignSurvey.indexOf(b.Id))
    .reduce(
      (fieldsAcc, { Definition, Id }) => [
        ...fieldsAcc,
        ...Definition.pages.map(page => ({
          ...page,
          elements: page.elements.map(element => ({ ...element, surveyId: Id })),
        })),
      ],
      [],
    );

  const surveyJson = {
    pages: orderedSurveysPages,
  };

  return { surveyJson, register, registerSurveyId: registerSurvey?.Id };
};

export default handleSurveys;
