import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import es from './locales/es.json';
import pt from './locales/pt.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { en: { common: en }, es: { common: es }, pt: { common: pt } },
    fallbackLng: 'es',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
