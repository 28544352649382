import {
  getCampaign,
  getCampaignLandingList,
  getCampaignEndingList,
  getCampaignEndingNoRedirectList,
  getCampaignTermsModalList,
  getCampaignSurveysList,
} from './campaign';
import { getTemplate } from './template';
import { getSurveys, putSurveyAnswers, getLocalizationByName } from './survey';

const api = {
  getCampaign,
  getCampaignLandingList,
  getCampaignEndingList,
  getCampaignEndingNoRedirectList,
  getCampaignTermsModalList,
  getCampaignSurveysList,
  getTemplate,
  getSurveys,
  putSurveyAnswers,
  getLocalizationByName,
};

export default api;
