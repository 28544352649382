import React from 'react';
import ErrorPage from '../ErrorPage';
import Loader from '~components/Loader';
import useGetTemplate from '~hooks/useGetTemplate';
import { useCampaign } from '~Flow';
import useSetLinks from '~hooks/useSetLinks';
import DangerouslySetHtmlContent from '~components/DangerouslySetHtmlContent';

const EndingPage = () => {
  const { endingHtml, termsModalHtml, error, loading } = useGetTemplate();

  const {
    campaign,
    url,
    userData: {
      firstname = '',
      token = '',
      lastname = '',
      email = '',
      dateOfBirth = '',
      sex = '',
      country = '',
      district = '',
      localization = '',
      city = '',
      zipcode = '',
      address = '',
      phone = '',
    },
    provider = '',
    leadId = '',
    clickId = '',
  } = useCampaign();
  useSetLinks(loading);

  if (!campaign['Redirect URL']) throw new Error('Redirect URL missing');

  if (error) return <ErrorPage errorCode={error} />;

  if (loading) return <Loader />;

  return (
    <DangerouslySetHtmlContent
      html={endingHtml
        .replaceAll(
          '{{redirect-url}}',
          campaign['Redirect URL']
            .replace('{{panelistToken}}', encodeURIComponent(token))
            .replace('{{provider}}', provider)
            .replace('{{leadId}}', leadId)
            .replace('{{clickId}}', clickId)
            .replace('{{firstname}}', encodeURIComponent(firstname))
            .replace('{{lastname}}', encodeURIComponent(lastname))
            .replace('{{email}}', encodeURIComponent(email))
            .replace('{{gender}}', encodeURIComponent(sex))
            .replace('{{dateOfBirth}}', encodeURIComponent(dateOfBirth))
            .replace('{{country}}', encodeURIComponent(country))
            .replace('{{district}}', encodeURIComponent(district))
            .replace('{{city}}', encodeURIComponent(city))
            .replace('{{zipcode}}', encodeURIComponent(zipcode))
            .replace('{{localizationId}}', encodeURIComponent(localization))
            .replace('{{address}}', encodeURIComponent(address))
            .replace('{{phone}}', encodeURIComponent(phone)),
        )
        .replaceAll('{{no-redirect-url}}', `${url}/ending-no-redirect`)
        .replaceAll('{{panelist-name}}', firstname)
        .replaceAll('{{terms-modal}}', termsModalHtml)}
    />
  );
};

export default EndingPage;
