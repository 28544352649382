import nocodb from './nocodb';

const TABLE_ID = process.env.NOCODB_CAMPAIGN_TABLE_ID;
const LANDING_LINK_ID = process.env.NOCODB_LANDING_LINK_ID;
const ENDING_LINK_ID = process.env.NOCODB_ENDING_LINK_ID;
const ENDING_NO_REDIRECT_LINK_ID = process.env.NOCODB_ENDING_NO_REDIRECT_LINK_ID;
const TERMS_MODAL_LINK_ID = process.env.NOCODB_TERMS_MODAL_LINK_ID;
const SURVEY_LINK_ID = process.env.NOCODB_SURVEY_LINK_ID;

export const getCampaign = async campaignId =>
  nocodb.get(`/tables/${TABLE_ID}/records/${campaignId}`, { headers: {} }).then(({ data }) => data);

export const getLinkedFields = (tableId, linkFieldId) => async campaignId =>
  nocodb
    .get(`/tables/${tableId}/links/${linkFieldId}/records/${campaignId}`)
    .then(({ data }) => data);

export const getCampaignLandingList = getLinkedFields(TABLE_ID, LANDING_LINK_ID);

export const getCampaignEndingList = getLinkedFields(TABLE_ID, ENDING_LINK_ID);

export const getCampaignEndingNoRedirectList = getLinkedFields(
  TABLE_ID,
  ENDING_NO_REDIRECT_LINK_ID,
);

export const getCampaignTermsModalList = getLinkedFields(TABLE_ID, TERMS_MODAL_LINK_ID);

export const getCampaignSurveysList = getLinkedFields(TABLE_ID, SURVEY_LINK_ID);
