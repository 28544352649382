/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import AwaitOpinaia from './components/AwaitOpinaia';
import AwaitPremium from './components/AwaitPremium';
import { useCampaign } from '~Flow';

const { FACEBOOK_PIXEL_ID } = process.env;

const Await = () => {
  const { campaign } = useCampaign();

  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${FACEBOOK_PIXEL_ID}');
  fbq('track', 'PageView');
  fbq('trackCustom', 'SpinnerFP');`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (campaign.Tipo === 'Opinaia') return <AwaitOpinaia />;

  return <AwaitPremium />;
};

export default Await;
