import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Flow from './Flow';

const App = () => (
  <Router>
    <Switch>
      <Route path="/flow/:campaignId">
        <Flow />
      </Route>
    </Switch>
  </Router>
);

export default App;
