import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCampaign } from '~Flow';

const useSetLinks = loading => {
  const history = useHistory();
  const { url } = useCampaign();

  useEffect(() => {
    if (!loading) {
      [...document.getElementsByTagName('a')].forEach(a => {
        if (a.href.startsWith(`${document.location.origin}${url}`)) {
          a.addEventListener('click', e => {
            e.preventDefault();
            history.push(a.href.replace(document.location.origin, ''));
          });
        }
      });
    }
  }, [loading]);
};

export default useSetLinks;
